import { Advisor, useGlobalContext } from '../../../contexts/global/GlobalContext';
import { ViewContainer } from '../../containers/ViewContainer';
import {useState, useEffect, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Theme from '../../../style/theme';
import { advisorImages, sceneImages } from './selectionImages';
import { ImageDiv } from '../../../style/types';
import { Button } from '../../buttons/Button';
import { IconButton } from '../../buttons/IconButton';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { BaseContainer } from '../../containers/BaseContainer';
import { SceneInformation } from './SceneInformation/SceneInformation';
import PopupContainer from '../../containers/PopupContainer';
import { advisors } from '../../../data/data';


export const Selection = () => {

	const {t} = useTranslation();

	const {gameState, setGameState, setSelectedAdvisor, setSelectedScene} = useGlobalContext();

	const [selectionState, setSelectionState] = useState<number>(0); // 0 === selecting building, 1 === selecting advisor

	const [showTutorial1, setShowTutorial1] = useState<boolean>(false);
	const [showTutorial2, setShowTutorial2] = useState<boolean>(false);

	const images = useMemo(() => {
		const images = gameState === 2 ? sceneImages : advisorImages;
		return Object.values(images);
	},[gameState]);

	const next = () => {
		setSelectionState(ss => {
			if(ss + 1 === images.length){
				return 0;
			}
			else{
				return ss + 1;
			}
		});
	};

	const previous = () => {
		setSelectionState(ss => {
			if(ss - 1 < 0){
				return images.length - 1;
			}
			else{
				return ss - 1;
			}
		});
	};

	const confirm = () => {
		if(gameState === 2){
			setSelectedScene(selectionState);
			setGameState(3);
		}
		else if(gameState === 3){
			setSelectedAdvisor(advisors[selectionState]);
			setGameState(4);
		}
	};

	useEffect(() => {
		if(gameState === 2){
			setSelectionState(0);
			setShowTutorial1(true);
		}
		if(gameState === 3){
			setShowTutorial2(true);
		}
	},[gameState]);

	return (
		<>
			{/* Scene Selection */}
			<StyledViewContainer activationGameState={2}>
				<Title>{t('views.selection.scene.title')}</Title>
				<Preview image={sceneImages[selectionState]}/>
				<div style={{display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'center'}}>
					<StyledIconButton onClick={() => previous()}><HiChevronLeft/></StyledIconButton>
					<Button onClick={() => confirm()}>{t('views.selection.confirm')}</Button>
					<StyledIconButton onClick={() => next()}><HiChevronRight/></StyledIconButton>
				</div>
				<Information>
					<SceneInformation selectionState={selectionState}/>
				</Information>
				<PopupContainer active={showTutorial1} titleKey={'views.selection.tutorial.scene.title'} onClose={() => setShowTutorial1(false)}>
					<p>{t('views.selection.tutorial.scene.description')}</p>
				</PopupContainer>
			</StyledViewContainer>
			{/* Advisor Selection */}
			<StyledViewContainer activationGameState={3}>
				<Title>{t('views.selection.advisor.title')}</Title>
				<Preview image={advisorImages[advisors[selectionState]]}/>
				<div style={{display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'center'}}>
					<StyledIconButton onClick={() => previous()}><HiChevronLeft/></StyledIconButton>
					<Button onClick={() => confirm()}>{t('views.selection.confirm')}</Button>
					<StyledIconButton onClick={() => next()}><HiChevronRight/></StyledIconButton>
				</div>
				<Information>
					<Content>
						<h1>{t(`views.selection.advisor.${advisors[selectionState]}.name`)} - {t(`views.selection.advisor.${advisors[selectionState]}.role`)}</h1>
						{/* <p><b>{t(`views.selection.advisor.${selectionState}.role`)}</b></p>
						<br/> */}
						<p>{t(`views.selection.advisor.${advisors[selectionState]}.description`)}</p>
					</Content>
				</Information>
				<PopupContainer active={showTutorial2} titleKey={'views.selection.tutorial.advisor.title'} onClose={() => setShowTutorial2(false)}>
					<p>{t('views.selection.tutorial.advisor.description')}</p>
				</PopupContainer>
			</StyledViewContainer>
		</>
	);
};

// styled components

const StyledViewContainer = styled(ViewContainer)`
	display: flex;
	flex-direction: column;
	padding-block: 0;
`;

const StyledIconButton = styled(IconButton)`
	font-size: 2.5em;
	padding: 0.25em;
`;

const Title = styled.h1`
	background-color: ${Theme.colors.neutralLightest};
	border-radius: 0em 0 2em 2em;
	padding: .5em 1em;
	text-align: center;
`;

const Preview = styled.div<ImageDiv>`

	flex-grow:1;
	flex-shrink: 0;

	background-image: url(${p => p.image});
    background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	margin-bottom: 20px;

`;

const Information = styled(BaseContainer)`
	height: 0;
	flex-grow: 1;
	background-color: ${Theme.colors.neutralLightest};
	border-radius: 2em 2em 0 0;
	margin-top: 20px;
	min-height: 0;
	display: flex;
	flex-direction: column;
	padding-bottom: 0;

	overflow: hidden;
`;

export const Content = styled.div`
	overflow-y: auto;

	& > p {
		margin:0;
	}
`;

