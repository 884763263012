import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { slideInBottom, slideOutBottom } from '../../../../style/animations';
import Theme from '../../../../style/theme';
import { IconButton } from '../../../buttons/IconButton';
import { BaseContainer } from '../../../containers/BaseContainer';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { Button } from '../../../buttons/Button';
import { IoClose } from 'react-icons/io5';
import Advisor from './Advisor';
import { Category, useGlobalContext } from '../../../../contexts/global/GlobalContext';
import { scenesData } from '../../../../data/data';
import { useThreeContext } from '../../../../contexts/three/ThreeContext';

export const Upgrades = () => {

	const {t} = useTranslation();

	const {activeCategory, setActiveCategory, selectedScene} = useGlobalContext();
	const {upgrade} = useThreeContext();

	const [category, setCategory] = useState<Category>('');

	const content = useRef<HTMLDivElement>(null);

	const upgrades = useMemo(() => {
		if(!activeCategory) return;
		return Object.keys(scenesData[selectedScene].categories[activeCategory].upgrades);
	},[selectedScene, activeCategory]);

	useEffect(() => {
		if(activeCategory){ 		
			setCategory(activeCategory);
		}
	},[activeCategory]);

	const [selectedUpgrade, setSelectedUpgrade] = useState<number>(0);

	useEffect(() => {
		if(!content.current) return;
		content.current.scrollTop = 0;
	}, [selectedUpgrade, category]);

	useEffect(() => {
		if(category){
			setSelectedUpgrade(0);
		}
	},[category]);

	const next = () => {
		if(!upgrades) return;
		setSelectedUpgrade(su => {
			if(su + 1 === upgrades.length){
				return 0;
			}
			else{
				return su + 1;
			}
		});
	};

	const previous = () => {
		if(!upgrades) return;
		setSelectedUpgrade(su => {
			if(su - 1 < 0){
				return upgrades.length - 1;
			}
			else{
				return su - 1;
			}
		});
	};

	const confirm = () => {
		if(!upgrades) return;
		upgrade && upgrade(upgrades[selectedUpgrade]);
		setActiveCategory('');
	};


	return (
		<Container category={activeCategory}>
			<Subcontainer category={activeCategory}>
				<SelectionRow> 
					<StyledIconButton onClick={previous}><HiChevronLeft/></StyledIconButton>
					<Button onClick={confirm}>Ik kies deze!</Button>
					<StyledIconButton onClick={next}><HiChevronRight/> </StyledIconButton>
				</SelectionRow>
				<Frame category={activeCategory}>

					{upgrades && <>
						<Header>
							<h1 style={{marginBottom: '0'}}>{t(`views.game.categories.${category}`)}</h1>
							<CloseButton onClick={() => setActiveCategory('')}>
								<IoClose/>
							</CloseButton>
						</Header>
						<hr style={{color: Theme.colors.neutralDarkest, width: '100%'}}/>
						<Content ref={content}>
							<h2 style={{marginBottom: '10px'}}>{t(`upgrades.${upgrades[selectedUpgrade]}.name`)}</h2>
							<p>{t(`upgrades.${upgrades[selectedUpgrade]}.description`).replace('Zowel in de kantine en kleedkamers als langs de sportvelden', selectedScene === 1 ? 'Zowel in de kantine als in de kleedkamers' : 'Zowel in de kantine en kleedkamers als langs de sportvelden')}</p>
							<br/>
							<Advisor category={category} selectedUpgrade={selectedUpgrade}/>
						</Content>
					</>}
				</Frame>
			</Subcontainer>
		</Container>
	);
};

// styled components

const Container = styled.div<{category: Category}>`

    position: relative;
    margin-top: auto;
    height: 70%;
    width: 100%;

	pointer-events: ${p => p.category ? 'all' : 'none'};

    // Extra small screens
	@media (max-width: ${p => p.theme.responsive.media.sm}){
		bottom: -${p => p.theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
		bottom: -${p => p.theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		bottom: -${p => p.theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		bottom: -${p => p.theme.responsive.whitespace.xl}px;
	}
	
`;

const Subcontainer = styled.div<{category: Category}>`
	position: absolute;  left:0;right:0;bottom:0;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-height: 0;
	animation: ${p => p.category ? slideInBottom : slideOutBottom} 1s forwards;
`;

const Frame = styled(BaseContainer)<{category: Category}>`
	flex-grow: 1;
	height :0;
	padding-bottom: 0;
    background-color:${Theme.colors.neutralLightest};
	border-radius: 1.5em 1.5em 0 0;
	display: flex; flex-direction: column;

`;

const SelectionRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;

	& > h2 {
		margin: 0;
	}
`;

const Content = styled.div`
	overflow-y: auto;
	overflow-x: hidden;

	& > p {
		margin:0;
	}
`;

const StyledIconButton = styled(IconButton)`
	font-size: 2.5em;
	padding: 0.25em;
`;

const Header = styled.div`
	display: flex;
	align-items:  top;

`;

const CloseButton = styled(IconButton)`
	padding:5px;
	font-size: 1.5em;

	height:fit-content;
	margin-left: auto;
`;

const AdvisorRow = styled.div`
	display: flex;
`;