import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { SceneInformationProps } from './SceneInformation';

export const Statistics = (props: SceneInformationProps) => {

	const {t} = useTranslation();

	return ( 
		<>
			{[...Array(4)].map((value, index) => 
				<>
					<Label>{t(`views.selection.statisticslabel.${index}`)}</Label>
					<Value>{t(`views.selection.scene.${props.selectionState}.statistic.${index}`)}</Value>
				</>
			)}

		</>
	);
};

const Label = styled.p`
    font-weight: 600;
	font-size: 1.2em;
	color: ${Theme.colors.neutral};
`;

const Value = styled.p`
    font-style: italic;
	padding-bottom: 15px;
`;
 
export default Statistics;