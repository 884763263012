import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
`;

export const slideInBottom = keyframes`
	0%{
		bottom: -200%;
	}
	100%{
		bottom: 0;
	}
`;

export const slideOutBottom = keyframes`
	100%{
		bottom: -200%;
	}
	0%{
		bottom: 0;
	}
`;
