
import { Suspense, lazy, useMemo } from 'react';
import { useGlobalContext } from '../../contexts/global/GlobalContext';
import WSI from '../WSI';

// import Scene01 from './Scene01';
// import Scene02 from './Scene02';
// import Scene03 from './Scene03';

const Scene01 = lazy(() => import('./Scene01'));
const Scene02 = lazy(() => import('./Scene02'));
const Scene03 = lazy(() => import('./Scene03'));

export const scenes = [
	Scene01,
	Scene02,
	Scene03,
];

const Scene = () => {

	const {gameState, selectedScene} = useGlobalContext();
	const CurrentScene = useMemo(() => {
		return scenes[selectedScene];
	},[selectedScene]);

	//Management functionaliteiten

	return (
		<>
			<Suspense fallback={true}>
				{ CurrentScene && <CurrentScene/>}
				<WSI/>
			</Suspense>
		</>
	);

};


export default Scene;
