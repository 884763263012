import scene0Image from '../../../assets/images/zwembad_image.png';
import scene1Image from '../../../assets/images/sporthal_image.png';
import scene2Image from '../../../assets/images/tennis_image.png';

import andreaImage from '../../../assets/images/advisor/andreaImage.png';
import arieanImage from '../../../assets/images/advisor/arieanImage.png';
import celesteImage from '../../../assets/images/advisor/celesteImage.png';
import justinImage from '../../../assets/images/advisor/justinImage.png';
import lisaImage from '../../../assets/images/advisor/lisaImage.png';

export const sceneImages = [
	scene0Image,
	scene1Image,
	scene2Image,
];

export const advisorImages = {
	'andrea' : andreaImage,
	'ariean' : arieanImage,
	'celeste' : celesteImage,
	'justin' : justinImage,
	'lisa' : lisaImage
};