import { Advisor } from '../contexts/global/GlobalContext';

export type Scene = {
	categories: {
		'besparen': Category, 
		'opwekken': Category,
		'opslaan': Category,
	}
}

type Category = {buttonPositions: number[], upgrades: {[key: string]: {cameraPos: number}}}

export type CameraPosition = [ // azimuth angle, polar angle 
	number, number
]

export const advisors: Advisor[] = [
	'andrea' ,
	'ariean', 
	'lisa',	
	'celeste', 
	'justin'
];

export const scenesData: Scene[] = [
	//scene1 zwembad
	{
		categories: {
			'besparen': {
				buttonPositions: [1, 1, -3],
				upgrades: {
					'warmtepomp': {cameraPos: 6},
					'zwembadafdekking': {cameraPos: 6},
					'dakisolatie': {cameraPos: 2},
				}
			},
			'opwekken': {
				buttonPositions: [1.5, 1, 1],
				upgrades: {
					'zonnepanelen': {cameraPos: 2},
					'thermischezonnepanelen': {cameraPos: 3},
					'warmteuitkunstgras': {cameraPos: 4},
				}
			},
			'opslaan': {
				buttonPositions: [-3, 1, 1],	
				upgrades: {	
					'warmteopslag': {cameraPos: 4},
					'batterij': {cameraPos: 4},
					'warmtekoudeopslag': {cameraPos: 4},
				}
			},
		}
	},
	//scene2 sporthal
	{
		categories: {
			'besparen': {
				buttonPositions: [1, 1, -3],	
				upgrades: {
					'ledverlichting': {cameraPos: 5},
					'warmtepomp': {cameraPos: 2},
					'gevelisolatie': {cameraPos: 2},
				}
			},
			'opwekken': {
				buttonPositions: [1.5, 1, 1],	
				upgrades: {
					'thermischezonnepanelen': {cameraPos: 5},
					'solarparking': {cameraPos: 2},
					'windmolens': {cameraPos: 3},
				}
			},
			'opslaan': {
				buttonPositions: [-3, 1, 1],
				upgrades: {
					'warmteopslag': {cameraPos: 4},
					'batterij': {cameraPos: 5},
					'warmtekoudeopslag': {cameraPos: 3},
				}
			},
		}
	},
	//scene3 tennisbaan
	{
		categories: {
			'besparen': {
				buttonPositions: [1, 1, -3],
				upgrades: {
					'ledverlichting': {cameraPos: 2},
					'koelenvriesinstallaties': {cameraPos: 2},
					'dakisolatie': {cameraPos: 2},
				}
			},
			'opwekken': {
				buttonPositions: [1.5, 1, 1],
				upgrades: {	
					'zonnepanelen': {cameraPos: 2},
					'warmteuitkunstgras': {cameraPos: 4},
					'windmolens': {cameraPos: 5},
				}
			},
			'opslaan': {
				buttonPositions: [-3, 1, 1],
				upgrades: {
					'warmteopslag': {cameraPos: 2},
					'batterij': {cameraPos: 2},
					'warmtekoudeopslag': {cameraPos: 6},
				}
			},
		}
	}

];

export const cameraPositions: CameraPosition[] = [// [azimuth angle, polar angle]
	[4,4],
	[0.63,0.70],
	[-0.81, 0.8],
	[2.27, 1.11],
	[1.11, 1.27],
	[3, 1],
];
