const Theme = {
	colors:{
		neutralLightest: 'hsl(250deg 0% 100%)',
		neutralLighter: 'hsl(250deg 0% 75%)',
		neutral: 'hsl(250deg 0% 50%)',
		neutralDarkest: 'hsl(250deg 0% 15%)',

		primary: 'hsl(204deg 100% 42%)',
		primaryLighter: 'hsl(204deg 100% 80%)'

	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace: {
			xs: 15,
			sm: 25,
			lg: 25,
			xl: 30,
		}
	}
};

export default Theme;
