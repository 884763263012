import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { ViewContainer } from '../../containers/ViewContainer';
import { Window } from '../../containers/Window';
import { LogoBar } from '../components/LogoBar';
import{ useTranslation } from 'react-i18next';
import { Button } from '../../buttons/Button';
import styled from 'styled-components';
import { fadeIn } from '../../../style/animations';

export const Introduction = () => {

	const {setGameState} = useGlobalContext();
	const {t} = useTranslation();

	return (
		<StyledViewContainer activationGameState={1} style={{display: 'flex', flexDirection:'column', justifyContent: 'center', gap: '25px'}}>
			<Window centerVertically style={{textAlign: 'center'}}>
				<h1>{t('views.introduction.title')}</h1>
				<p>
					{t('views.introduction.description')}
				</p>
				<LogoBar/>
			</Window>
			<ButtonContainer><Button onClick={() => setGameState(s => s + 1)}>{t('views.introduction.start-button')}</Button></ButtonContainer>
		</StyledViewContainer>
	);
};

// styled components

const StyledViewContainer = styled(ViewContainer)`
	animation: ${fadeIn} 1s forwards;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	opacity: 0;
	animation: ${fadeIn} 1s forwards;
	animation-delay: 1s;
`;