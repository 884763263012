import styled from 'styled-components';
import Theme from '../../style/theme';

export const Button = styled.button`

    outline: none;
    border: unset;

    background-color: ${Theme.colors.neutralLightest};
    color: ${Theme.colors.primary};
    border-radius: 2rem;

    // Typography

    font-weight: 900;
    font-size: 1em;


    box-shadow: 0 0 0.2rem black;


    transition: all 0.2s;
    
    cursor: pointer;

    &:hover {
        background-color: ${Theme.colors.primary};
        color:${Theme.colors.neutralLightest};
        /* transform: translateY(-5px); */
        box-shadow: 0 5px 0.5rem black;
        transform: scale(1.05);
    }

    &:active{
        /* transform: translateY(0); */
        box-shadow: 0 0 0.2rem black;
        transform: scale(1);

    }

    &:disabled{
        pointer-events: none;
        border: none;
        filter: grayscale(100%);
    }


    @media (max-width: ${p => p.theme.responsive.media.lg}){
        border: solid 5px ${Theme.colors.primary};
    }

    @media (min-width: ${p => p.theme.responsive.media.lg}){
        border: solid 3px ${Theme.colors.primary};
    }

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		padding: .5em 1em;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: 1em 1.5em;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: 1em 1.5em;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: 1em 1.5em;
	}
`;