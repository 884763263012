import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { useThreeContext } from '../../../contexts/three/ThreeContext';
import {useEffect, useMemo, useState} from 'react';
import { scenesData } from '../../../data/data';

export const Upgrades = () => {

	const {upgrade, setControlsEnabled, controlsEnabled, activeUpgrades} = useThreeContext();
	const { selectedScene } = useGlobalContext();

	const [upgradeNumber, setUpgradeNumber] = useState<string>('');

	const allUpgrades = useMemo(() => {
		let all: string[] = [];
		Object.entries(scenesData[selectedScene].categories).map((cat) => all = [...all, ...Object.keys(cat[1].upgrades)]);
		return all;
	},[selectedScene]);

	return(<>
		{upgrade && selectedScene &&<>
			<select onChange={(e) => setUpgradeNumber && setUpgradeNumber(e.currentTarget.value)} value={upgradeNumber}>
				{allUpgrades.map((value, index) => {
					return(
						<option key={`upgrade-${value}`}>{value}</option>
					);
				})}
			</select>
			<button onClick={() => upgrade(upgradeNumber)}>upgrade</button>
			{activeUpgrades}
			<button onClick={() => setControlsEnabled && setControlsEnabled(!controlsEnabled)}>toggle controls</button>
		</>
		}
	</>
	);
};

