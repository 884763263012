import { useContextBridge } from '@react-three/drei';
// import { CursorState } from './ThreeCursorState';
import { GlobalContext } from '../contexts/global/GlobalContext';
import Scene from './scenes';
import { ThreeCanvas } from './ThreeCanvas';
import { ThreeControls } from './ThreeControls';

export const Three = () => {

	const ContextBridge = useContextBridge(GlobalContext);
    
	return (
		<ThreeCanvas>
			<ContextBridge>
				{/* <CursorState/> */}
				<ThreeControls/>
				{/* pas de scene renderen als gameState op 3 staat */}
				<Scene/>
			</ContextBridge>
		</ThreeCanvas>
	);

};