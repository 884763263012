import styled from 'styled-components';
import town from '../assets/images/town.png';
import town0 from '../assets/images/town0.png';
import town1 from '../assets/images/town1.png';
import town2 from '../assets/images/town2.png';
import { useGlobalContext } from '../contexts/global/GlobalContext';

// data

const backdrops = [
	town0,
	town1,
	town2
]; 

const Backdrop = () => {

	const {gameState} = useGlobalContext();

	return ( 
		<Container>
			<StandardImage/>
			<Image visible={gameState === 4} level={0}/>
			<Darken/>
		</Container>
	);

};

// styled components

const Container = styled.div`
	position: absolute;
	inset:0;

	z-index: 0;
`;

const Image = styled.div<{level?:number, visible: boolean}>`
	position: absolute;
	left:0;
	top:0;

	background-image: url(${p => p.level !== undefined && backdrops[p.level]});

	opacity: ${p => p.visible ? 1 : 0};
	
	background-size: cover;
	background-repeat: no-repeat;
	filter: blur(5px);
	height: 100%;
	width: 100%;

	transition: opacity 1s;
`; 

const StandardImage = styled.div`
	position: absolute;
	left:0;
	top:0;

	background-image: url(${town});
	background-size:cover;
	filter: blur(5px);
	height: 100%;
	width: 100%;

	transition: opacity 1s;
`; 

const Darken = styled.div`
	position: absolute;
	background-color: rgba(0,0,0,0.5);
	height: 100%;
	width: 100%;
`;


 
export default Backdrop;