import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Theme from '../../style/theme';
import { BaseContainer } from './BaseContainer';

export const Window = (props: WindowProps) => {
	return (
		<Container>
			<Content {...props}>
				{/* make scrolling */}
				{props.children}
			</Content>
		</Container>
	);
};

// styled components

const Container = styled(BaseContainer)`
	position: relative;
	height: fit-content;
	min-height: 0;
	background-color: ${Theme.colors.neutralLightest};
	border-radius: 1.5em;
	display: flex;
`;

const Content = styled.div`
	overflow-y: auto;
	width: 100%;
`;

// types

interface WindowProps extends HTMLAttributes<HTMLDivElement> {
	centerVertically?: boolean;
}