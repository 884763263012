import { ReactNode } from 'react-markdown/lib/react-markdown';
import { GlobalContextProvider } from './global/GlobalContext';
import { ThreeContextProvider } from './three/ThreeContext';

// this provides 
const ContextProvider = (props:{children: ReactNode}) => {
	return (			
		<GlobalContextProvider>
			<ThreeContextProvider>
				{props.children}
			</ThreeContextProvider>
		</GlobalContextProvider>			

	);
};

export default ContextProvider;