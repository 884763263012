import { Content } from '../Selection';

import { HiOutlineInformationCircle } from 'react-icons/hi';
import { RiNumbersFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Theme from '../../../../style/theme';
import { useState} from 'react';
import Description from './Description';
import Statistics from './Statistics';

export const SceneInformation = (props: SceneInformationProps) => {

	const {t} = useTranslation();

	const [selectedTab, setSelectedTab] = useState<number>(0);

	return (
		<>
			<Header>
				<Border/>
				<Tabs onClick={() => setSelectedTab(0)} selected={selectedTab === 0}><HiOutlineInformationCircle/></Tabs>
				<Tabs onClick={() => setSelectedTab(1)} selected={selectedTab === 1}><RiNumbersFill/></Tabs>
				<h1>{t(`views.selection.scene.${props.selectionState}.title`)}</h1> 
			</Header>
			<Content>
				<br/>
				{ selectedTab === 0 &&
					<Description selectionState={props.selectionState}/>
				}
				{ selectedTab === 1 &&
					<Statistics selectionState={props.selectionState}/>
				}		
			</Content>
		</>
	);
};


const Header = styled.div`
	position: relative;
	display: flex;

	gap: 10px;

	& > h1 {
		margin-left: 20px;
		margin-bottom:0;
	}

	margin-bottom: 10px;
`;

const Border = styled.div `
	position: absolute;
	left:-100%;right:-100%;
	bottom: 0;
	height: 2px;
	background-color: ${Theme.colors.primary};
`;

const Tabs = styled.button<{selected: boolean}>`

	position: relative;

	border: 0;
	background-color: transparent;
	color: ${Theme.colors.primary};
	font-size: 3em;
	cursor: pointer;

	display:flex;
	align-items: center;

	border-radius: 15px 15px 0 0;
	border: solid 2px ${Theme.colors.primary};

	${p => p.selected ? css`
		background-color: ${Theme.colors.primary};
		color: ${Theme.colors.neutralLightest};
		border-bottom: none;
	` : css `
	`}
`;

// type

export type SceneInformationProps = {
	selectionState: number;
}