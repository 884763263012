import CameraControls from 'camera-controls';
import { createContext, Dispatch, useContext, useState, SetStateAction, useEffect } from 'react';

const GlobalContext = createContext<GlobalContextProps>({
	gameState: 0,
	setGameState: () => null,
	overwriteGameState: undefined,
	setOverwriteGameState: () => null,
	selectedScene: 0,
	setSelectedScene: () => null,
	selectedAdvisor: 'andrea',
	setSelectedAdvisor: () => null,
	controls: undefined,
	setControls: () => null,
	activeCategory: '',
	setActiveCategory: () => null,
	meter: 1,
	setMeter: () => null,
});

const GlobalContextProvider = (props: JSX.ElementChildrenAttribute) => {

	const [gameState, setGameState] = useState<number>(1);
	const [overwriteGameState, setOverwriteGameState] = useState<number | undefined>();

	// detemines which level is loaded when showing the game view.
	const [selectedLevel, setSelectedLevel] = useState<number>(0);

	const [controls, setControls] = useState<CameraControls | undefined>();

	const [selectedAdvisor, setSelectedAdvisor] = useState<Advisor>('andrea');
	const [selectedScene, setSelectedScene] = useState<number>(0);
	const [activeCategory, setActiveCategory] = useState<Category>('');

	const [meter, setMeter] = useState<number>(1);

	// set overwriteGameState on load.
	useEffect(() => {
		const ov = localStorage.getItem('overwriteGameState');
		if(ov === 'undefined' || ov === null || ov === 'null') return;
		setOverwriteGameState(parseInt(ov));
		setGameState(parseInt(ov));
	},[]);

	const passedValues = {
		gameState,
		setGameState,
		overwriteGameState,
		setOverwriteGameState,

		selectedLevel,
		setSelectedLevel,

		controls,
		setControls,

		// selection

		setSelectedAdvisor,
		selectedAdvisor,
		setSelectedScene,
		selectedScene,

		activeCategory,
		setActiveCategory,

		meter,
		setMeter

	};		

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues}}>
			<>{props.children}</>
		</GlobalContext.Provider>
	);

	//#endregion
};

// types

type GlobalContextProps = {

	// general
	gameState: number,
	setGameState: Dispatch<SetStateAction<number>>;
	activeCategory: Category,
	setActiveCategory: Dispatch<SetStateAction<Category>>;
	overwriteGameState : number | undefined,
	setOverwriteGameState : Dispatch<SetStateAction<number| undefined>>;

	//game
	selectedScene: number;
	setSelectedScene: Dispatch<SetStateAction<number>>;
	selectedAdvisor: Advisor;
	setSelectedAdvisor: Dispatch<SetStateAction<Advisor>>;

	controls: CameraControls | undefined
	setControls: Dispatch<SetStateAction<CameraControls | undefined>>

	meter: number;
	setMeter: Dispatch<SetStateAction<number>>;

}

// exports
export type Category = 'besparen' | 'opwekken' | 'opslaan' | ''
export type Advisor = 'andrea' | 'ariean' | 'lisa' | 'celeste' | 'justin'


const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
