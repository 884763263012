import styled from 'styled-components';
import { ViewContainer } from '../../containers/ViewContainer';
import { FaQuestion} from 'react-icons/fa';
import { IconButton } from '../../buttons/IconButton';
import PopupContainer from '../../containers/PopupContainer';
import { useState, useEffect } from 'react';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useTranslation } from 'react-i18next';
import { Meter } from './Meter';
import { Upgrades } from './upgrades/Upgrades';
import { Button } from '../../buttons/Button';

export const Game = () => {

	const {gameState, setGameState, meter, setMeter} = useGlobalContext();

	const {t} = useTranslation();

	const [showTutorial, setShowTutorial] = useState<boolean>(false);

	useEffect(() => {
		if(gameState === 4){
			setMeter(1);
			setTimeout(() => setShowTutorial(true), 2000);
		}
	},[gameState]);

	return (
		<>
			<ViewContainer activationGameState={4} style={{pointerEvents: 'none'}} disablePointerEvents>
				<StyledIconButtons onClick={() => setShowTutorial(true)}><FaQuestion/></StyledIconButtons>
				<Meter meter={meter}/>
				{ meter === 4 && <StyledButton onClick={() => setGameState(5)}>
					{t('views.game.endgamebutton')}
				</StyledButton>}
				<div style={{height: '30%', width: '100%', pointerEvents: 'none'}}/>
				<Upgrades/>
				{/* tutorial popup */}
				<PopupContainer active={showTutorial} titleKey={'views.game.tutorial.title'} onClose={() => setShowTutorial(false)}>
					<ReactMarkdown>{t('views.game.tutorial.description')}</ReactMarkdown>
					<div style={{paddingBlock: '10px'}}/>
					<ReactMarkdown>{t('views.game.tutorial.titleControls')}</ReactMarkdown>
					<ReactMarkdown>{t('views.game.tutorial.descriptionControls')}</ReactMarkdown>
				</PopupContainer>
			</ViewContainer>
		</>

	);
};

// styled components

const StyledIconButtons = styled(IconButton)`
	height: fit-content;
	/* padding: 0.5em; */
	position: absolute;
	left:20px;top:20px;
`;

const StyledButton = styled(Button)`
	position: absolute;
	bottom:20%;
	left:46.5%;
	/* transform: translate(-50%); */
`;