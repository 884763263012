/* eslint-disable react/no-unknown-property */
import { useTexture} from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { inpo_0_hover, inpo_0_normal, inpo_1_hover, inpo_1_normal, inpo_2_hover, inpo_2_normal} from '../../assets/icons';
import { useEffect, useRef, useState } from 'react';
import { Category, useGlobalContext } from '../../contexts/global/GlobalContext';

const scale = 0.7;

const InteractivePoint = (props:{cat: Category, positions: number[]}) => {

	const { setActiveCategory } = useGlobalContext();

	const textures = useTexture({normal_besparen: inpo_0_normal, hover_besparen: inpo_0_hover, normal_opwekken: inpo_1_normal, hover_opwekken: inpo_1_hover, normal_opslaan: inpo_2_normal, hover_opslaan: inpo_2_hover});

	const [state, setState] = useState<string>('normal');

	const {camera} = useThree();

	useFrame(() => {
		if(pole.current){
			pole.current.lookAt(camera.position.x, pole.current.position.y, camera.position.z);
		}
	});

	const pole = useRef<any>();
	const ref = useRef<any>();
	
	return (  
		<>
			<sprite ref={ref}
				position={[props.positions[0], props.positions[1], props.positions[2]]} 
				scale={[1 * scale, 1 * scale, 0 * scale]}
				onPointerEnter={() => setState('hover')} 
				onPointerLeave={() => setState('normal')}
				onClick={() => {setActiveCategory && setActiveCategory(props.cat);}}
				//transparent
			>
				{ state === 'normal' ?
					<> 
						<spriteMaterial map={textures[`normal_${props.cat}`]} />
					</>
					:
					<>
						<spriteMaterial map={textures[`hover_${props.cat}`]} />
					</>
				}
			</sprite>
			{/* <mesh ref={pole} position={[props.positions[0], props.positions[1] - 0.48, props.positions[2]]} >
				<planeBufferGeometry attach="geometry" args={[0.045, 0.7]}/>
				<boxGeometry args={[5, 1, 1]}/>

				{ state === 'normal' ?
					<> 
						<meshBasicMaterial attach="material" map={textures.hover_s} transparent/>
					</>
					:
					<>
						<meshBasicMaterial attach="material" map={textures.hover_s} transparent/>
					</>
				
				}
			</mesh> */}
		</>
	);
};

// styled components

// const Label = styled.div`
// 	background: green;
// 	tran
// `;

 
export default InteractivePoint;