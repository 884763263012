import { nl_pool_upgrades } from './nl_pool_upgrades';
import { nl_sport_upgrades } from './nl_sport_upgrades';
import { nl_tennis_upgrades } from './nl_tennis_upgrades';

export const nl_upgrades = {
	0: nl_pool_upgrades,
	1: nl_sport_upgrades,
	2: nl_tennis_upgrades,

	ledverlichting:{
		name : 'Ledverlichting',
		description: 'Met het vervangen van “traditionele” lampen door ledlampen kun je veel energie besparen. Zowel in de kantine en kleedkamers als langs de sportvelden. De kwaliteit van het licht neemt met 10% tot 15% toe door een hogere lichtopbrengst. Dit wordt door sporters als positief ervaren.',
	},
	gevelisolatie: {
		name : 'Gevelisolatie',
		description: 'Duurzame gevelisolatie (binnen- of buitengevelisolatie) zorgt voor een enegiebesparing en een comfortabele accommodatie. Hoe beter de gevelisolatie, hoe hoger de besparing. Hoeveel besparing er gehaald wordt, is afhankelijk van de huidige isolatie van het gebouw en hoeveel energiekosten naar het verwarmen of koelen van de accommodatie gaan. ',
	},
	warmtepomp:{
		name : 'Warmtepomp',
		description: 'Met een warmtepomp haal je warmte uit lucht, bodem of (grond)water om je gebouw en/of het kraanwater te verwarmen. De technische ontwikkelingen maken het mogelijk om met een volledig elektrische warmtepomp (all-electric) helemaal aan de warmtevraag van je sportaccommodatie te voldoen.',
	},
	zwembadafdekking:{
		name : 'Zwembadafdekking',
		description: 'Een isolerende zwembadafdekking om binnen- en buitenbaden af te dekken, met als doel het warmteverlies via de wateroppervlakte te beperken. ',
	},
	koelenvriesinstallaties: {
		name : 'Koel- en vriesinstallaties',
		description: 'Verwijder oude koel- en vriesinstallaties (welke je gratis hebt gekregen van je leden) en vervang ze voor een energiezuinige versie. Dit verdien je direct terug op de energierekening. Zet de installaties uit in bepaalde periodes, bijvoorbeeld tijdens de winterstop als ze niet of minimaal gebruikt worden.  ',
	},
	dakisolatie:{
		name : 'Dakisolatie',
		description: 'Dakisolatie of een groendak zorgt voor een beter binnenklimaat en het ondersteunt de natuurlijke omgeving waardoor sporters prettiger kunnen sporten. Dakisolatie is het isoleren van het bestaande dak, de zolder- of vlieringvloer. ',
	},
	zonnepanelen:{
		name : 'Zonnepanelen',
		description: 'Met zonnepanelen kan een sportaccommodatie zelf de energie opwekken die zij nodig heeft, met behulp van de zon. Wordt er meer energie opgewekt dan nodig, dan kun je deze energie terugleveren aan het elektriciteitsnet, opslaan of bijvoorbeeld verkopen via laadpalen.  ',
	},
	thermischezonnepanelen:{
		name : 'Thermische zonnepanelen',
		description: 'Thermische zonnepanelen of zonnecollectoren zijn panelen waarin een leiding met vloeistof loopt. Ze werken als een “zonneboiler”, want de werking is hetzelfde als een boiler waarin het water wordt opgewarmd. Meestal gebruikt men thermische zonnepanelen in combinatie met een klassiek verwarmingssysteem. Op die manier kun je besparen op de energie die je nodig hebt voor de opwarming van het water. De opgewarmde vloeistof dient als warmtebron voor zowel verwarming van ruimtes als van het water.',
	},
	solarparking:{
		name : 'Solar parking',
		description: 'De parkeerplaats bij de sportaccommodatie wordt overkapt met zonnepanelen. Dit zorgt voor een optimaal gebruik van de ruimte en je wekt duurzame ernergie op! De parkeerplaats kan zelfs nog worden voorzien van elektrische laadpunten waardoor er een extra inkomstenbron ontstaat. Want waarom energie terugleveren aan het elektriciteitsnet als het direct gebruikt kan worden voor het opladen van elektrische voertuigen? ',
	},
	warmteuitkunstgras:{
		name : 'Warmte uit kunstgras',
		description: 'Terwijl spelers aan het sporten zijn, kan door middel van een ingenieus systeem warmte worden gewonnen uit het kunstgrasveld. Het systeem ligt verborgen onder het veld en kan clubgebouwen en omliggende woningen van warmte voorzien. Deze techniek heeft geen nadelige invloed op de veiligheid en het kwaliteit.',
	},
	windmolens:{
		name : 'Windmolens',
		description: 'Kleine windturbines (tot 15 meter, zogenaamde buurtmolens) kunnen een bijdrage leveren aan de ambitie van de sportaccommodaties om energieneutraal te worden. Vooral in delen van het land waar het veel waait. Investeren in turbines is met name rendabel als de accommodatie de opgewekte energie (nagenoeg) zelf benut.',
	},
	warmteopslag:{
		name : 'Warmteopslag',
		description: 'Dit is een warmteopslagsysteem dat water op hoge temperatuur (95º C) opslaat over langere perioden (> 6 maanden), met een zeer beperkt energieverlies. Dit is een syteem dat zich goed leent om te koppelen aan thermische zonnepanelen. Dan wordt zowel elektrische als thermische energie opgewekt. De thermische energie gaat direct naar de warmteopslag.',
	},
	batterij:{
		name : 'Batterij',
		description: 'Batterijsystemen brengen vraag en aanbod van energie in evenwicht en vermijden aanzienlijke investeringen in elektriciteitsnetwerken. Opslag van elektriciteit gaat een onvermijdelijke rol spelen. Met het toepassen van een batterij kun je voorzien in de toenemende elektriciteitsvraag, in een tijd waar het leveren en terugleveren van energie niet altijd mogelijk is. ',
	},
	warmtekoudeopslag:{
		name : 'Warmte-koudeopslag',
		description: 'Met warmte-koudeopslag (WKO) kun je het clubgebouw duurzaam verwarmen en koelen. In combinatie met een warmtepomp maak je het mogelijk om op een duurzame en betrouwbare manier je sportaccommodatie comfortabel te verwarmen. ',
	},
};