import { useMemo } from 'react';
import styled from 'styled-components';
import { ImageDiv } from '../../../style/types';
import logo from '../../../assets/images/logo.png';

export const LogoBar = () => {

	return(
		<Image image={logo}/>
	);    

};

export const Image = styled.div<ImageDiv>`
    background-image: url(${p => p.image});
    background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

    height: 70px;
    width: 100%;
	margin-top:100px;
`;
