import { useTranslation } from 'react-i18next';
import { SceneInformationProps } from './SceneInformation';

const Description = (props: SceneInformationProps) => {

	const {t} = useTranslation();

	return ( 
		<>
			<p>
				{t(`views.selection.scene.${props.selectionState}.description`)}	
			</p>
			<br/>
		</>
	);
};
 
export default Description;