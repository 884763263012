import styled, { css } from 'styled-components';
import {HTMLAttributes} from 'react';
import { BaseContainer } from './BaseContainer';
import { useGlobalContext } from '../../contexts/global/GlobalContext';

export const ViewContainer = (props : ViewContainerProps) => {

	const {gameState} = useGlobalContext();

	return (
		<CenteringContainer gameState={gameState} activationGameState={props.activationGameState} disablePointerEvents={props.disablePointerEvents} >
			<Container {...props} interactable={gameState === props.activationGameState}>
				{props.children}
			</Container>
		</CenteringContainer>
	);
};

// styled components

const Container = styled(BaseContainer)<{interactable: boolean}>`
	min-height: 0;

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		width: 70%;
	}

	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		width: 50%;
	}

	& > * {
		pointer-events: ${p => p.interactable ? 'auto' : 'none'};
	}
`;

const CenteringContainer = styled.div<{gameState: number, activationGameState: number, disablePointerEvents: boolean | undefined}>`
	position: absolute;
	inset:0;
	display: flex;
	justify-content: center;
	height: 100%;
	opacity: ${p => p.gameState === p.activationGameState ? 1 : 0};
	pointer-events: ${p => p.disablePointerEvents ? 'none' : p.gameState === p.activationGameState ? 'auto' : 'none'};
	transition: all 0.4s;
	transition-delay: ${p => p.gameState === p.activationGameState ? '.5s' : ''};
`;

// type

interface ViewContainerProps extends HTMLAttributes<HTMLDivElement>{
	activationGameState: number;
	fullWidth?: boolean;
	disablePointerEvents?: boolean;
}