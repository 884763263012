/* eslint-disable react/no-unknown-property */
import { Canvas } from '@react-three/fiber';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const ThreeCanvas = (props: {children: ReactNode}) => {

	return (
		<CanvasContainer>
			<Canvas camera={{position: [4, 4, 4], fov: 80}} frameloop={'always'}>
				<directionalLight position={[5,10,20]} intensity={0.5} color={'white'}/>
				<ambientLight intensity={0.5} color={'white'}/>
				{props.children}
			</Canvas>
		</CanvasContainer>
	);
	
};

// styled components

const CanvasContainer = styled.div`

	height: 100%;
    width: 100%;

`; 