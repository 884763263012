export const nl_pool_upgrades = {
	warmtepomp:{
		'lisa':'Hiermee ga je veel besparen en het is een belangrijke stap richting een CO2-arme accommodatie. Gaan we die besparing terugzien in de huurprijs? ',
		'justin':'Goed dat jullie een warmtepomp gaan installeren. Dit is een onmisbare stap naar een gasloze accommodatie. Let op: je gaat hiermee wel meer elektriciteit gebruiken. Check daarom of je elektriciteitsaansluiting toereikend is. ',
		'celeste':'We vinden het heel goed dat jullie gaan verduurzamen. Laat je goed informeren over de de locatie van de warmtepomp.',
		'ariean':'Super dat jullie een warmtepomp gaan installeren, dat gaan jullie zeker merken in de energiekosten. Zorg je voor een goed onderhoudscontract? ',
		'andrea':'Heel goed dat je een warmtepomp gaat installeren. Weet je of het zwembad goed genoeg geïsoleerd is? Dat is noodzakelijk i.v.m. het rendement van de warmtepomp.',
	},

	zwembadafdekking:{
		'lisa':'Hiermee ga je veel energie besparen! Daarmee helpen we het klimaat weer een beetje. Kunnen onze vrijwilligers dit systeem ook bedienen? ',
		'justin':'Een eenvoudige toepassing om energiebesparing te realiseren. Waar wacht je nog op?',
		'celeste':'Dat is een goede stap naar een duurzamer zwembad. Gaat de huurder, de zwemvereniging ook een vermindering van de huur zien door de lagere energiekosten? ',
		'ariean':'Top dat jullie voor zwembadafdekking kiezen! Zorg dat deze gelijk wordt opgenomen in het MJOP (meerjarenonderhoudsplan) voor toekomstige vervanging.',
		'andrea':'Deze is eenvoudig subsidiabel te maken, bovendien levert deze (nacht)afdekking een flinke besparing aan aardgas op.',
	},

	dakisolatie:{
		'lisa':'Heel mooi dat isoleren, maar de vereniging hiernaast is bezig met zonnepanelen. Dat wil ik ook. Moeten we daar niet mee beginnen? ',
		'justin':'Het toepassen van dakisolatie is een goede stap in het verduurzamen van je sportaccommodatie. Heb je je goed laten informeren over welke isolatietechniek voor jullie accommodatie het beste is?',
		'celeste':'Het isoleren van het dak is een mooie stap in het verlagen van de energierekening, maar ik heb gehoord dat de verzekeraar soms moeilijk doet over het type dakisolatie als je zonnepanelen wil gaan plaatsen. Kun je eens checken bij de energiecoach of hij weet hoe dat zit? ',
		'ariean':'Heb je alle mogelijkheden goed bekeken? En heb je  isolatie van binnenuit ook goed in beeld? Dit kan aanzienlijk schelen t.o.v. isoleren vanuit de buitenkant. Pas dakisolatie met een Rc-waarde van minstens 3,5 [m2K/W] toe.  ',
		'andrea':'Voor isolatie kun je verschillende subsidies aanvragen. Bij sommige subsidies is het een voorwaarde dat je twee of meer energiebesparende maatregelen uitvoert. Let daar even op!',
	}
	,
	
	zonnepanelen:{
		'lisa':'Wij hebben een sponsor die de zonnepanelen goedkoper wil plaatsen. Sponsor blij, wij blij!',
		'justin':'Ik ga je in contact brengen met de lokale energiecoöperatie. Zij kunnen je helpen bij het aanvragen van SCE-subsidie. Zo kun je meer mensen laten profiteren van de zonne-energie. Dat is toch mooi?',
		'celeste':'Je kunt overleggen of er nog meer sportaccommodaties zijn die zonnepanelen willen plaatsen. Dan kunnen wij ondersteunen bij een collectieve inkoop.',
		'ariean':'Weet je wie de eigenaar van het dak is? Check bij je opstalverzekering welke eisen worden gesteld voor het plaatsen van zonnepanelen. Dat hebben ze meestal zo voor je klaar liggen. ',
		'andrea':'Wat goed dat je je eigen groene energie gaat opwekken. Weet je wanneer de dakrenovatie gepland staat? Zo kun je onnodige dubbele kosten voorkomen. En controleer wat dit betekent voor de omvang van je elektriciteitsaansluiting.',
	},

	thermischezonnepanelen:{
		'lisa':'Thermische zonnepanelen: wat is dat precies? Kunnen we contact leggen met een ander zwembad die dit al heeft toegepast? Ik zou hun ervaringen wel eens willen horen. Kun jij een zwembad opzoeken in de duurzaamheidsatlas?',
		'justin':'Onze lokale energiecoöperatie heeft op dit moment een actie voor het realiseren van thermische zonnepanelen. Dat scheelt financieel mogelijk op de inkoop en realisatie!',
		'celeste':'Ik heb gehoord dat thermische zonnepanelen goed combineren met een warmtepomp. Hebben jullie daar al naar gekeken? Verder is dit een grote stap richting een gasloze sportaccommodatie. Ik ga jullie voordragen voor de Sportaccommodatie van het jaar! ',
		'ariean':'Dit is een techniek die de conventionele zonnestroom (PV) en -warmte (thermisch) combineert in één (PVT)-paneel! Een PVT-paneel kan op daken, land en water geïnstalleerd worden. Denk bij realisatie dus ook verder dan het dak.',
		'andrea':'Je kunt de Investeringssubsidie Duurzame Energie en Energiebesparing (ISDE) gebruiken voor de installatie van thermische zonnepanelen. De voorwaarden kun je checken in de subsidiewijzer.',
	},

	warmteuitkunstgras:{
		'lisa':'Dat klinkt als een toekomstmuziek. Is het technisch al mogelijk en blijft de kwaliteit van het veld even goed als nu? ',
		'justin':'Een goede toepassing om invulling te geven aan de opgave vanuit de warmtetransitie. Neem als sportccommodatie vooral een faciliterende rol in door je accommodatie beschikbaar te stellen. ',
		'celeste':'Dit is een mooie grote stap in verduurzaming van de sportaccommodatie. Ik zou dit graag met andere clubs en gemeenten delen, kunnen we jullie ervaringen delen in de duurzaamheidsatlas? ',
		'ariean':'Dit is echt een mooie techniek die jullie hier toepassen. De toekomst is nu, kun je wel zeggen. Is het wel zo dat dit kunstgrasveld eenzelfde levensduur heeft als wij hebben opgenomen in de planning? ',
		'andrea':'Ik ken deze techniek nog onvoldoende, schakel een andere expert in om dit goed te organiseren. ',
	},

	warmteopslag:{
		'lisa':'Kun je met warmteopslag snel genoeg warm water leveren? Dat is belangrijk tijdens piekmomenten, na trainingen en wedstrijden.',
		'justin':'Het gebruiken van warmteopslag is een mooie aanvulling op de energiebesparende maatregelen die jullie al toe hebben gepast. Heb je al geïnformeerd of er subsidies mogelijk zijn?',
		'celeste':'De tennisclub op julie sportpark is ook bezig met verduurzamen en wil ook warmteopslag gaan aanleggen. Kunnen jullie informeren of het mogelijk is om hierin samen te werken? ',
		'ariean':'Heel mooi dat jullie warmte op willen slaan! Hebben jullie goed gekeken waar deze geplaatst kan worden en wie de eigenaar van deze grond is? ',
		'andrea':'Het installeren van warmteopslag is een mooie stap richting aardgasvrij. Heb je geïnformeerd wat dit betekent voor je elektriciteitsverbruik? ',
	},

	batterij:{
		'lisa':'Als vereniging hoeven wij geen technische kennis te hebben van deze aanpassing toch? ',
		'justin':'Goed dat je gebruik gaat maken van energieopslag met een batterij! Dit is een grote stap om als sportaccommodatie zelfvoorzienend te worden in energie. ',
		'celeste':'Ik heb gehoord dat er accommodaties zijn die hun batterij koppelen aan laadpalen voor electrische auto\'s. Dit zijn extra inkomsten. Misschien kun je die mogelijkheid onderzoeken?',
		'ariean':'Nu kun je het gebruik van de opgewekte energie goed spreiden. Ook kun je de elektriciteitsaansluiting verkleinen! Dat gaat veel schelen in je vaste lasten.',
		'andrea':'Wat goed dat jullie energie op willen slaan met een batterij. Heb je de verschillende financieringsmogelijkheden onderzocht? Dat kan een groot verschil maken in je businesscase. ',
	},

	warmtekoudeopslag:{
		'lisa':'Wat gaat dit ons eigenlijk opleveren? En kunnen we die besparing misschien inzetten voor verdere verduurzaming? ',
		'justin':'Goed te horen dat jullie warmte-koudeopslag meenemen in de plannen. We zijn ook plannen aan het maken voor de aanleg van een warmtenet. Misschien kunnen jullie hierop aansluiten, dan hoef je geen onnodige investeringen te doen.',
		'celeste':'Door het gebruik van warmte- en koudeopslag gaan jullie fors besparen. Dat past perfect binnen de ambities van het lokale sportakkoord!',
		'ariean':'Ik heb begrepen dat jullie gebruik gaan maken van warmte-koudeopslag. Hebben jullie al overleg gehad met mijn collega van financiën over financiering en subsidies? ',
		'andrea':'Warmte-koudeopslag wordt al regelmatig toegepast bij zwembaden. Ik ga jullie in contact brengen, dan kunnen jullie eens kijken hoe het daar geregeld is.',
	}
};