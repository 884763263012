/* eslint-disable react/prop-types */
function LinkRenderer(props) {
	// console.log({ props });
	return (
		<a href={props.href} target="_blank" rel="noreferrer">
			{props.children}
		</a>
	);
}

export {LinkRenderer};