import {HTMLAttributes} from 'react';
import styled, { keyframes } from 'styled-components';
import { IconButton } from '../buttons/IconButton';
import { BaseContainer } from './BaseContainer';
import { Window } from './Window';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

export const PopupContainer = (props: PopupContainerProps) => {

	const {t} = useTranslation();

	return ( 
		<Container active={props.active}>
			<Content>
				<StyledWindow>
					<>
						<Header>
							<h1>{t(props.titleKey)}</h1>
							<CloseButton onClick={() => props.onClose()}>
								<IoClose/>
							</CloseButton>
						</Header>
						{props.children}
					</>
				</StyledWindow>
			</Content>
		</Container>
	);
};

// styled components

const OpenPopup = keyframes`
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
		pointer-events: all;
	}

`;

const ClosePopup = keyframes`
	0%{
		opacity: 1;
		pointer-events: none;
	}
	100%{
		opacity: 0;
		pointer-events: none;
	}
`;

const Container = styled.div<{active: boolean}>`
	position: absolute;
	inset:0;
	padding:0;

	background-color: rgb(0,0,0,0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	opacity: 0;
	animation: ${p => p.active ? OpenPopup : ClosePopup} 0.4s forwards;
`;

const Content = styled(BaseContainer)`
	height: fit-content;
	max-height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
  	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		width: 70%;
	}

	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		width: 50%;
	}
`;

const CloseButton = styled(IconButton)`
	padding:5px;
	font-size: 1.5em;
	height:fit-content;
	margin-left: auto;
`;

const Header = styled.div`
	display: flex;
	align-items:  top;

`;
const StyledWindow = styled(Window)`
	overflow: visible;
	display: flex;
	flex-direction: column;
`;

// types

interface PopupContainerProps extends HTMLAttributes<HTMLDivElement> {
	active: boolean;
	onClose: () => void;
	titleKey: string;
}
 
export default PopupContainer;