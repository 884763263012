import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useGlobalContext } from '../global/GlobalContext';

const ThreeContext = createContext<Partial<threeContextProps>>({});

const ThreeContextProvider = (props: contextProps) => {

	//#region useStates
	const {selectedScene, meter, setMeter, gameState} = useGlobalContext();

	const controls = useRef();
	
	const setControls = (newRef) => {
		controls.current = newRef;
	};

	const [controlsEnabled, setControlsEnabled] = useState<boolean>(true);
	const [ chosenScene, setChosenScene] = useState<string>('');

	const [ zoom, setZoom] = useState<number>(5);

	const [ activeUpgrades, setActiveUpgrades] = useState<string[]>([]);
	const [newestUpgrade, setNewestUpgrade] = useState<string>();

	const upgrade = (index: string) => {
		//set array to play the right upgrade
		const nextList = [...activeUpgrades];
		nextList.push(index);
		setActiveUpgrades(nextList);
		//set meter
		setMeter(meter+1);
		setNewestUpgrade(index);
		//remove interaction while upgrading
		setControlsEnabled(false);
		//rotate camera
		//camera is rotated through useEffect in threecontrols.tsx which listens to activeUpgrades
	};

	//#region use effects

	useEffect(()=>{
		if(gameState === 4)
			setActiveUpgrades([]);
	},[gameState]);

	//#endregion

	//#region Passed Props

	const passedFunctions = {
		controls,
		chosenScene,
		zoom,
		activeUpgrades,
		controlsEnabled,
		newestUpgrade
	};

	const passedValues = {
		setControls,
		setChosenScene,
		setZoom,
		setActiveUpgrades,
		upgrade,
		setControlsEnabled,
		setNewestUpgrade
	};

	//#endregion

	//#render

	return (
		<ThreeContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</ThreeContext.Provider>
	);

	//#endregion
};

export type contextProps = {
    children: ReactNode
}

export type threeContextProps = {
    controls : any | null;
    setControls : (val:any | null) => void;
    chosenScene : string;
    setChosenScene : (val: string) => void;
    zoom : number;
    setZoom : (val: number) => void;
    
    activeUpgrades: string[]
    setActiveUpgrades : (val: string[]) => void;
	upgrade : (val: string) => void;

	controlsEnabled : boolean;
    setControlsEnabled : (val: boolean) => void;
	
	newestUpgrade : string;
    setnewestUpgrade : (val: string) => void;
}

const useThreeContext = () => useContext(ThreeContext);

export {ThreeContextProvider, ThreeContext, useThreeContext};
