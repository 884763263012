export const nl_tennis_upgrades = {
	ledverlichting:{
		'lisa':'Top! Betere lichtkwaliteit voor de sporters, lagere energielasten voor de vereniging! Kunnen wij de lichtstanden zelf instellen? Gaat dit nog verschil maken voor omwonenden? ',
		'justin':'Dit is één van de belangrijkste stappen in CO2-reductie. Gemiddeld 50% tot 60% van de energielasten van de vereniging, zijn kosten voor de traditionele sportverlichting.',
		'celeste':'Er zijn twee andere verenigingen in gemeente Watertoren die ook ledsportverlichting willen realiseren. Als jullie het samen oppakken scheelt het in de kosten en kunnen jullie kennis delen!',
		'ariean':'Heb je gecontroleerd wie de eigenaar is van de verlichtingsinstallatie? En wist je dat ledverlichting 50% op de kosten van je onderhoudscontract scheelt?',
		'andrea':'Naast directe besparing op je energiekosten, kun je misschien ook je electriciteitsaansluiting verkleinen, omdat je minder energie gaat verbruiken. Dat kan veel schelen in je vaste maandelijkse lasten. En vergeet niet om subsidie aan te vragen!',
	},
	koelenvriesinstallaties:{
		'lisa':'Ik snap dat we willen besparen op energie, maar ik weet niet of dit zoveel gaat opleveren. Heb je goed gekeken naar het verschil in verbruik per jaar? ',
		'justin':'Dit is een goed begin in energiebesparing, maar als je aan de klimaatdoelstellingen wil voldoen moet je ook echt naar grotere maatregelen gaan kijken zoals isolatie of ledverlichting. Heb je daar ook aan gedacht? ',
		'celeste':'Wat goed dat jullie energiezuinige koel- en vriesinstallaties gaan plaatsen. Misschien is dit ook het moment om naar een gezond aanbod in de kantine te kijken. Ik hoor overal dat dat ook minder afval geeft. ',
		'ariean':'Een quick win! Gebruik de financiële besparing als investering in extra maatregelen om je clubgebouw ook te isoleren.',
		'andrea':'Deze oude installaties kom ik zoveel tegen bij sportaccommodaties. Ik begrijp daar helemaal niets van. Ik durf ze bijna niet in het energierapport te melden. Ga het vooral direct organiseren.',
	},
	dakisolatie:{
		'lisa':'Heel mooi dat isoleren, maar de vereniging hiernaast is bezig met zonnepanelen. Dat wil ik ook. Moeten we daar niet mee beginnen? ',
		'justin':'Het toepassen van dakisolatie is een goede stap in het verduurzamen van je sportaccommodatie. Heb je je goed laten informeren over welke isolatietechniek voor jullie accommodatie het beste is?',
		'celeste':'Het isoleren van het dak is een mooie stap in het verlagen van de energierekening, maar ik heb gehoord dat de verzekeraar soms moeilijk doet over het type dakisolatie als je zonnepanelen wil gaan plaatsen. Kun je eens checken bij de energiecoach of hij weet hoe dat zit? ',
		'ariean':'Heb je alle mogelijkheden goed bekeken? En heb je  isolatie van binnenuit ook goed in beeld? Dit kan aanzienlijk schelen t.o.v. isoleren vanuit de buitenkant. Pas dakisolatie met een Rc-waarde van minstens 3,5 [m2K/W] toe.  ',
		'andrea':'Voor isolatie kun je verschillende subsidies aanvragen. Bij sommige subsidies is het een voorwaarde dat je twee of meer energiebesparende maatregelen uitvoert. Let daar even op!',
	},
                        
	zonnepanelen:{
		'lisa':'Wij hebben een sponsor die de zonnepanelen goedkoper wil plaatsen. Sponsor blij, wij blij!',
		'justin':'Ik ga je in contact brengen met de lokale energiecoöperatie. Zij kunnen je helpen bij het aanvragen van SCE-subsidie. Zo kun je ook groene stroom aan je leden verkopen. Dat is toch mooi?',
		'celeste':'Je kunt overleggen of er nog meer verenigingen zijn die zonnepanelen willen plaatsen. Dan kunnen wij ondersteunen bij een collectieve inkoop.',
		'ariean':'Weet je wie de eigenaar van het dak is? Check bij je opstalverzekering welke eisen worden gesteld voor het plaatsen van zonnepanelen. Dat hebben ze meestal zo voor je klaar liggen. ',
		'andrea':'Wat goed dat je je eigen groene energie gaat opwekken. Weet je wanneer de dakrenovatie gepland staat? Zo kun je onnodige dubbele kosten voorkomen. En controleer wat dit betekent voor de omvang van je elektriciteitsaansluiting.',
	},
	warmteuitkunstgras:{
		'lisa':'Dat klinkt als een toekomstmuziek. Is het technisch al mogelijk en blijft de kwaliteit van het veld even goed als nu? ',
		'justin':'Een goede toepassing om invulling te geven aan de opgave vanuit de warmtetransitie. Neem als club vooral een faciliterende rol in door je accommodatie beschikbaar te stellen. ',
		'celeste':'Dit is een mooie grote stap in verduurzaming van de sportaccommodatie. Ik zou dit graag met andere clubs en gemeenten delen, kunnen we jullie ervaringen delen in de duurzaamheidsatlas? ',
		'ariean':'Dit is echt een mooie techniek die jullie hier toepassen. De toekomst is nu, kun je wel zeggen. Is het wel zo dat dit kunstgrasveld eenzelfde levensduur heeft als jullie hebben opgenomen in de planning? ',
		'andrea':'Ik ken deze techniek nog onvoldoende, schakel een andere expert in om dit goed te organiseren. ',
	},
	windmolens:{
		'lisa':'Veroorzaken windmolens extra geluidsoverlast? Gaan onze sporters hier iets van merken?',
		'justin':'Met een ashoogte van 15 meter zijn deze windmolens een stuk vriendelijker dan de witte windreuzen. En, het blijken ook serieuze energieopwekkers te zijn op sommige sportparken!',
		'celeste':'Wat mooi dat jullie windenergie willen opwekken. Hebben jullie al gekeken of er omwonenden/ leden  zijn die willen meedoen aan deze ontwikkeling en ook energie van de windmolen willen afnemen? ',
		'ariean':'Wat een mooie ontwikkeling dat jullie gebruik gaan maken van windenergie. Dat is zichtbare reclame voor een duurzame gemeente. Hebben jullie het bestemmingsplan goed gecheckt? ',
		'andrea':'Wat doet het mij goed te zien dat jullie gebruik gaan maken van windenergie. Zorg dat je je onderhoudskosten goed doorrekent. Dat wordt nog wel eens vergeten en dan komt de molen stil te staan. Dat zou natuurlijk zonde zijn. ',
	},
                        
	warmteopslag:{
		'lisa':'Kun je met warmteopslag snel genoeg warm water leveren? Dat is belangrijk tijdens piekmomenten, na trainingen en wedstrijden.',
		'justin':'Het gebruiken van warmteopslag is een mooie aanvulling op de energiebesparende maatregelen die jullie al toe hebben gepast. Heb je al geïnformeerd of er subsidies mogelijk zijn?',
		'celeste':'De andere vereniging op julie sportpark is ook bezig met verduurzamen en wil ook warmteopslag gaan aanleggen. Kunnen jullie informeren of het mogelijk is om hierin samen te werken? ',
		'ariean':'Heel mooi dat jullie warmte op willen slaan! Hebben jullie goed gekeken waar deze geplaatst kan worden en wie de eigenaar van deze grond is? ',
		'andrea':'Het installeren van warmteopslag is een mooie stap richting aardgasvrij. Heb je geïnformeerd wat dit betekent voor je elektriciteitsverbruik? ',
	},
	batterij:{
		'lisa':'Kunnen wij al die technische toepassingen zelf wel bedienen en onderhouden? En levert het ook nog wat op?',
		'justin':'Goed dat je gebruik gaat maken van energieopslag met een batterij! Dit is een grote stap om als sportvereniging zelfvoorzienend te worden in energie. ',
		'celeste':'Ik heb gehoord dat er clubs zijn die hun batterij koppelen aan laadpalen voor electrische auto\'s. Dit zijn extra inkomsten voor de clubkas. Misschien kun je die mogelijkheid onderzoeken?',
		'ariean':'Nu kun je je opgewekte energie het hele jaar door gebruiken. Ook kun je de elektriciteitsaansluiting verkleinen! Dat gaat veel schelen in je vaste lasten.',
		'andrea':'Wat goed dat jullie energie op willen slaan met een batterij. Heb je de verschillende financieringsmogelijkheden onderzocht? Dat kan een groot verschil maken in je businesscase. ',
	},
	warmtekoudeopslag:{
		'lisa':'Wat gaat dit ons eigenlijk opleveren? En kunnen we die besparing misschien inzetten voor de aanleg van een nieuw veld? ',
		'justin':'Goed te horen dat jullie warmte-koudeopslag meenemen in de plannen. We zijn ook plannen aan het maken voor de aanleg van een warmtenet. Misschien kunnen jullie hierop aansluiten, dan hoef je geen onnodige investeringen te doen.',
		'celeste':'Door het gebruik van warmte- en koudeopslag gaan jullie fors besparen. Dat past perfect binnen de ambities van het lokale sportakkoord!',
		'ariean':'Ik heb begrepen dat jullie gebruik gaan maken van warmte-koudeopslag. Hebben jullie al overleg gehad met mijn collega van financiën over financiering en subsidies? ',
		'andrea':'Warmte-koudeopslag wordt al regelmatig toegepast bij zwembaden. Ik ga jullie in contact brengen, dan kunnen jullie eens kijken hoe het daar geregeld is.',
	},
};