import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Category, useGlobalContext } from '../../../../contexts/global/GlobalContext';
import { scenesData } from '../../../../data/data';
import Theme from '../../../../style/theme';
import { ImageDiv } from '../../../../style/types';
import { advisorImages } from '../../selection/selectionImages';
import { useMemo } from 'react';

const Advisor = (props: AdvisorProps) => {

	const {selectedAdvisor, selectedScene, activeCategory} = useGlobalContext();
	const {t} = useTranslation();

	const upgrades = useMemo(() => {
		if(!activeCategory) return;
		return Object.keys(scenesData[selectedScene].categories[activeCategory].upgrades);
	},[selectedScene, activeCategory]);

	return ( 
		<>
			<Row>
				<Portrait image={advisorImages[selectedAdvisor]}/>
				<h2 style={{marginBottom: '0'}}>{t('views.game.upgrades.advisorTitle')} {t(`views.selection.advisor.${selectedAdvisor}.name`)}?</h2>
				{upgrades && <p>{t(`upgrades.${selectedScene}.${upgrades[props.selectedUpgrade]}.${selectedAdvisor}`)}</p>}
			</Row>
		</> 
	);
};

const Row = styled.div`
    /* display: flex; */
    /* align-items: flex-start; */
`;

const Portrait = styled.div<ImageDiv>`
    float: left;
    width: 25%;
    aspect-ratio: 1/1;
    margin-right: 20px;
    flex-shrink: 0;
    background: ${Theme.colors.primaryLighter};
    border-radius: 1em;
	margin-bottom: 10px;

    background-image: url(${p => p.image});
    background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
`;

type AdvisorProps = {
	category: Category;
	selectedUpgrade: number;
}

 
export default Advisor;