import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Theme from '../../../style/theme';

export const Meter = (props: MeterProps) => {

	const {t} = useTranslation();

	return(
		<Container>
			<p>{t('views.game.meter.title')}</p>
			<Bar>
				<BarBackdrop/>
				<BarIndicator style={{left: `${props.meter === 1 ? '5' :
					props.meter === 2 ? '30' :
						props.meter === 3 ? '60' :
							props.meter === 4 ? '90' :
								'0'}%`}}/>
			</Bar>
		</Container>
	);
};

// styled components

const Container = styled.div`
    position: absolute;
    right:0;
    top:0;
    width: 200px;

    background-color: white;
    border-radius: 0 0 0 1em;

    & > p {
        width: 100%;
        padding: 0;
        color: ${Theme.colors.primary};
        font-weight: 900;
        margin-bottom: 5px;
    }

    padding: 20px;
    // Extra small screens
	@media (max-width: ${p => p.theme.responsive.media.sm}){
        width: 75%;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
        width: 80%;

	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
        width: 500px;

	}

	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
        width: 700px;
	}


`;

const Bar = styled.div`
    position: relative;
    height: 30px;
    width: 100%;
`;

const BarIndicator = styled.div`
    position: absolute;
    left:0;bottom:-5px;

    width: 15px; height: 20px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: black;
    transition: all 1s;
`;

const BarBackdrop = styled.div`
    position: absolute;
    inset: 0;
    border-radius: 3em;
    background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,235,0,1) 60%, rgba(31,255,0,1) 100%); 
`;

// types

type MeterProps = {
    meter: number;
}