import { useGlobalContext } from '../../../contexts/global/GlobalContext';
import { Button } from '../../buttons/Button';
import { ViewContainer } from '../../containers/ViewContainer';
import { Window } from '../../containers/Window';
import { useTranslation } from 'react-i18next';
import { LogoBar } from '../components/LogoBar';
import { Fireworks } from '@fireworks-js/react';
import { useRef, useEffect, ReactNode } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { LinkRenderer } from './link';

export const End = () => {

	const {gameState, setGameState} = useGlobalContext();
	const {t} = useTranslation();

	const fireworks = useRef<any>();

	useEffect(() => {
		if(gameState === 5){
			fireworks.current.start();
			setTimeout(() => {fireworks.current.waitStop();}, 4000);
		}
	},[gameState]);

	return (
		<>
			<ViewContainer activationGameState={5} style={{display: 'flex', flexDirection:'column', justifyContent: 'center', gap: '25px'}}>
				<Window centerVertically style={{textAlign: 'center'}}>
					<h1>{t('views.end.title')}</h1>
					<p>
						{t('views.end.description1')}
					</p>
					<ReactMarkdown>
						{t('views.end.description2')}
					</ReactMarkdown>
					<ReactMarkdown>
						{t('views.end.description3')}
					</ReactMarkdown>
					<br/>
					<h1>Links</h1>
					<ReactMarkdown components={{ a: LinkRenderer }}>
						{t('views.end.link1')}
					</ReactMarkdown>
					<ReactMarkdown components={{ a: LinkRenderer }}>
						{t('views.end.link2')}
					</ReactMarkdown>
					<ReactMarkdown components={{ a: LinkRenderer }}>
						{t('views.end.link3')}
					</ReactMarkdown>
					<ReactMarkdown components={{ a: LinkRenderer }}>
						{t('views.end.link4')}
					</ReactMarkdown>
					<LogoBar/>
				</Window>
				<div style={{display: 'flex', justifyContent:'center'}}><Button onClick={() => location.reload()}>{t('views.end.replay-button')}</Button></div>
				<Fireworks 
					ref={fireworks}
					style={{
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						position: 'absolute',
						pointerEvents: 'none'
					}}
				/>
			</ViewContainer>
		</>

	);
};