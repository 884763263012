export const nl_sport_upgrades = {
	ledverlichting: {
		'lisa':'Top! Betere lichtkwaliteit voor de sporters, lagere energielasten voor de vereniging! Kunnen wij de lichtstanden zelf instellen? ',
		'justin':'Dit is één van de belangrijkste stappen in CO2-reductie. Gemiddeld 50% tot 60% van de energielasten zijn kosten voor de traditionele sportverlichting.',
		'celeste':'Er zijn twee andere sporthallen in gemeente Watertoren die ook ledverlichting willen realiseren. Als jullie het samen oppakken scheelt het in de kosten en kunnen jullie kennis delen!',
		'ariean':'Heb je gecontroleerd wie de eigenaar is van de verlichtingsinstallatie? En wist je dat ledverlichting 50% op de kosten van je onderhoudscontract scheelt?',
		'andrea':'Naast directe besparing op je energiekosten, kun je misschien ook je electriciteitsaansluiting verkleinen, omdat je minder energie gaat verbruiken. Dat kan veel schelen in je vaste maandelijkse lasten. En vergeet niet om subsidie aan te vragen!',
	},
	warmtepomp: {
		'lisa':'Hiermee ga je veel besparen en het is een belangrijke stap richting een CO2-arme accommodatie. ',
		'justin':'Goed dat jullie een warmtepomp gaan installeren. Dit is een onmisbare stap naar een gasloze accommodatie. Let op: je gaat hiermee wel meer elektriciteit gebruiken. Check daarom of je elektriciteitsaansluiting toereikend is. ',
		'celeste':'We vinden het heel goed dat jullie gaan verduurzamen. Laat je goed informeren over de de locatie van de warmtepomp.',
		'ariean':'Super dat jullie een warmtepomp gaan installeren, dat gaan jullie zeker merken in minder gasverbuik. Zorg je voor een goed onderhoudscontract? Dan weet je zeker dat de warmtepomp optimaal blijft functioneren.',
		'andrea':'Heel goed dat je een warmtepomp gaat installeren. Weet je of jullie accommodatie goed genoeg geïsoleerd is? Dat is noodzakelijk i.v.m. het rendement van de warmtepomp.',
	},
	gevelisolatie: {
		'lisa':'Goed dat je gaat isoleren. Wij hebben nog een goede aannemer in de vereniging, misschien kan het werk uitvoeren?',
		'justin':'Het toepassen van gevelisolatie is een goede stap in het verduurzamen van je sportaccommodatie. Heb je je goed laten informeren over welke techniek van isoleren voor jullie het beste is? ',
		'celeste':'Top dat jullie een stap zetten in de verduurzaming van de sportaccommodatie! Hebben jullie een goede afweging gemaakt of dit de meest rendabele oplossing is voor deze investering? ',
		'ariean':'Goed dat jullie gaan investeren in isolatie, dat gaat een mooie besparing opleveren. Heb je je ook laten informeren over isolatie in combinatie met de ventilatie? ',
		'andrea':'Op de investering in isolatie, zijn verschillende subsidies mogelijk. Bij sommige subsidies is het een voorwaarde dat je twee of meer energiebesparende maatregelen uitvoert. Goed om op te letten!',
	},
                        
	thermischezonnepanelen: {
		'lisa':'Thermische zonnepanelen: wat is dat precies? Kunnen we contact leggen met een andere sportaccommodatie die dit al hebben toegepast? Ik zou hun ervaringen wel eens willen horen. Kun jij een accommodatie opzoeken in de duurzaamheidsatlas?',
		'justin':'Onze lokale energiecoöperatie heeft op dit moment een actie voor het realiseren van thermische zonnepanelen. Dat scheelt financieel mogelijk op de inkoop en realisatie!',
		'celeste':'Ik heb gehoord dat thermische zonnepanelen goed combineren met een warmtepomp. Hebben jullie daar al naar gekeken? Verder is dit een grote stap richting een gasloze sportaccommodatie. Ik ga jullie voordragen voor de Sportaccommodatie van het jaar! ',
		'ariean':'Dit is een techniek die de conventionele zonnestroom (PV) en -warmte (thermisch) combineert in één (PVT)-paneel! Een PVT-paneel kan op daken, land en water geïnstalleerd worden.',
		'andrea':'Je kunt de Investeringssubsidie Duurzame Energie en Energiebesparing (ISDE) gebruiken voor de installatie van thermische zonnepanelen. De voorwaarden kun je checken in de subsidiewijzer.',
	},
	solarparking: {
		'lisa':'De solarparking geeft ons een duurzame uitstraling naar bezoekers en de buurt, maar wie is eigenlijk verantwoordelijk voor het toezicht en onderhoud? ',
		'justin':'Dit is een mooie zichtbare duurzame maatregel. Wist je dat je de opgewekte zonne-energie ook kunt verkopen via laadpalen?',
		'celeste':'Je gaat iets realiseren in de openbare ruimte. Vergeet niet om op de juiste manier toestemming te vragen bij de gemeente!',
		'ariean':'Heel mooi dat jullie duurzame energie op gaan wekken, daar werken we graag aan mee. De parkeerplaats is eigendom van de gemeente dus we moeten goede afspraken maken. ',
		'andrea':'Voor de solarparking heb je een aparte aansluiting nodig. Vergeet niet om deze en andere realisatiekosten mee te nemen in je businesscase!',
	},
	windmolens: {
		'lisa':'Veroorzaken windmolens extra geluidsoverlast? Gaan onze sporters hier iets van merken? Ik heb ook wel eens gehoord van horizontale windturbines op daken. Zou dat hier ook kunnen? ',
		'justin':'Met een ashoogte van 15 meter zijn deze windmolens een stuk vriendelijker dan de witte windreuzen. En, het blijken ook serieuze energieopwekkers te zijn op verschillende sportparken!',
		'celeste':'Wat mooi dat jullie windenergie willen opwekken. Hebben jullie al gekeken of er omwonenden zijn die willen meedoen aan deze ontwikkeling en ook energie van de windmolen willen afnemen? ',
		'ariean':'Wat een mooie ontwikkeling dat jullie gebruik gaan maken van windenergie. Dat is zichtbare reclame voor een duurzame gemeente. Hebben jullie het bestemmingsplan goed gecheckt? ',
		'andrea':'Wat doet het mij goed te zien dat jullie gebruik gaan maken van windenergie. Zorg dat je je onderhoudskosten goed doorrekent. Dat wordt nog wel eens vergeten en dan komt de molen stil te staan. Dat zou natuurlijk zonde zijn. ',
	},
                        
	warmteopslag: {
		'lisa':'Kun je met warmteopslag snel genoeg warm water leveren? Dat is belangrijk tijdens piekmomenten, na trainingen en wedstrijden.',
		'justin':'Het gebruiken van warmteopslag is een mooie aanvulling op de energiebesparende maatregelen die jullie al toe hebben gepast. Heb je al geïnformeerd of er subsidies mogelijk zijn?',
		'celeste':'De andere korfbalvereniging op julie sportpark is ook bezig met verduurzamen en wil ook warmteopslag gaan aanleggen. Kunnen jullie informeren of het mogelijk is om hierin samen te werken? ',
		'ariean':'Heel mooi dat jullie warmte op willen slaan! Hebben jullie goed gekeken waar deze geplaatst kan worden en wie de eigenaar van deze grond is? ',
		'andrea':'Het installeren van warmteopslag is een mooie stap richting aardgasvrij. Heb je geïnformeerd wat dit betekent voor je elektriciteitsverbruik? ',
	},
	batterij: {
		'lisa':'Kunnen wij al die technische toepassingen zelf wel bedienen en onderhouden? En levert het ook nog wat op?',
		'justin':'Goed dat je gebruik gaat maken van energieopslag met een batterij! Dit is een grote stap om als sporthal zelfvoorzienend te worden in energie. ',
		'celeste':'Ik heb gehoord dat er sportaccommodaties zijn die hun batterij koppelen aan laadpalen voor electrische auto\'s. Dit zijn extra inkomsten. Misschien kun je die mogelijkheid onderzoeken?',
		'ariean':'Nu kun je je opgewekte energie spreiden in gebruik. Ook kun je de elektriciteitsaansluiting verkleinen! Dat gaat veel schelen in je vaste lasten.',
		'andrea':'Wat goed dat jullie energie op willen slaan met een batterij. Heb je de verschillende financieringsmogelijkheden onderzocht? Dat kan een groot verschil maken in je businesscase. ',
	},
	warmtekoudeopslag: {
		'lisa':'Wat gaat dit ons eigenlijk opleveren? En kunnen we die besparing misschien inzetten voor nog meer duurzame toepassingen? ',
		'justin':'Goed te horen dat jullie warmte-koudeopslag meenemen in de plannen. We zijn ook plannen aan het maken voor de aanleg van een warmtenet. Misschien kunnen jullie hierop aansluiten, dan hoef je geen onnodige investeringen te doen.',
		'celeste':'Door het gebruik van warmte- en koudeopslag gaan jullie fors besparen. Dat past perfect binnen de ambities van het lokale sportakkoord!',
		'ariean':'Ik heb begrepen dat jullie gebruik gaan maken van warmte-koudeopslag. Hebben jullie al overleg gehad met mijn collega van financiën over financiering en subsidies? ',
		'andrea':'Warmte-koudeopslag wordt al regelmatig toegepast bij zwembaden. Ik ga jullie in contact brengen, dan kunnen jullie eens kijken hoe het daar geregeld is.',
	},
};