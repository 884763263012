import { useEffect, useState } from 'react';
import { Category, useGlobalContext } from '../../contexts/global/GlobalContext';
import { useThreeContext } from '../../contexts/three/ThreeContext';
import { scenesData } from '../../data/data';
import InteractivePoint from './InteractivePoint';


const WSI = () => {

	//const {upgradePanelActive, categoriesCompleted, showCategories, cameraPos} = useGlobalContext();
	const {selectedScene, meter} = useGlobalContext();	
	//const [amountCompleted , setAmountCompleted] = useState<number>(0);

	// useEffect(() => {
	// 	if(categoriesCompleted){
	// 		let completed = 0;
	// 		categoriesCompleted.map((value) => {
	// 			if(value)
	// 				completed++;
	// 		});
	// 		setAmountCompleted(completed);
	// 	}
	// }, [categoriesCompleted]);

	return ( 		
		<>
			{ selectedScene != undefined && Object.entries(scenesData[selectedScene].categories).map((value, index) => {
				if(meter-1 === index)
					return(
						<InteractivePoint key={`interactive-point-${index}`} cat={value[0] as Category} positions={value[1].buttonPositions}/>
					);
			}) }
		</>
	);
};
 
export default WSI;

