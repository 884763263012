import { End } from './views/end/End';
import { Game } from './views/game/Game';
import { Introduction } from './views/introduction/Introduction';
import { Selection } from './views/selection/Selection';

export const Interface = () => {
	return(
		<>
			<Introduction/>
			<Selection/>
			<Game/>
			<End/>
		</>
	);
};