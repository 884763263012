import styled from 'styled-components';
import { ImageDiv } from '../../style/types';
import { Button } from './Button';
import { HTMLAttributes } from 'react';
import Theme from '../../style/theme';


export const IconButton = (props: IconButtonProps) => {
	return(
		<StyledButton {...props}>
			{props.children}
		</StyledButton>
	);
};

// styled components

const StyledButton = styled(Button)`
	position: relative;
	padding: 0.5em;
	height: 100%;
	aspect-ratio: 1/1;
	font-size: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		padding: .5em;
		font-size: 1em;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding:0.5em;
		font-size: 1.5em;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding:.5em;
		font-size: 3em;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding:.5em;
		font-size: 3em;
	}

`;
// type

type IconButtonProps = HTMLAttributes<HTMLButtonElement>