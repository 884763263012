import tutorialImage from '../../assets/images/tutorialimage.png';
import { nl_upgrades } from './upgrades/nl_upgrades';

export const nl = {
	views : {
		introduction: {
			title: 'Verduurzaming game',
			description: 'Welkom in de gemeente Watertoren! We hebben je hulp nodig om een sportaccommodatie te verduurzamen. Start de game en help Watertoren met de CO2-reductie door energiebesparing, opwek van duurzame energie en energieopslag.',
			'start-button': 'Start de game'
		},
		selection: {
			tutorial: {
				scene: {
					title: 'Het kiezen van een accommodatie',
					description: 'Iedere accommodatie heeft zijn eigen mogelijkheden en uitdagingen. Maak keuzes en start met het verduurzamen van gemeente Watertoren.'
				},
				advisor: {
					title: 'Schakel hulp in',
					description: 'Maak je geen zorgen, je staat er niet alleen voor met verduurzamen. Deze slimme koppen gaan je op weg helpen.'
				}
			},
			confirm: 'ik kies deze!',
			statisticslabel: {
				0: 'Huidig energieverbruik',
				1: 'Huidig gasverbruik',
				2: 'Bouwjaar',
				3: 'Wie is de eigenaar?',
			},
			scene: {
				title: 'Kies een accommodatie',
				0: {
					title: 'Zwembad',
					description: 'Het zwembad is al in de jaren 60 gebouwd. Het is een hoge kostenpost voor gemeente Watertoren, maar de belevingswaarde van de plek is heel groot. De bewoners zijn erg gehecht aan de plek. Het is een belangrijke ontmoetings- en beweegplek voor ouderen.',
					statistic: {
						0: '75.000 kWh/jaar',
						1: '450.000 m³/jaar',
						2: '1968',
						3: 'Eigendom van gemeente, verhuur situatie met exploitant.',
					}
				},
				1: {
					title: 'Sporthal',
					description: 'De sporthal is een belangrijk monumentaal pand voor de gemeente Watertoren. Op dit moment wordt de sporthal gebruikt door de plaatselijke dans-, turn-, en volleybalvereniging.',
					statistic: {
						0: '50.000 kWh/jaar',
						1: '30.000 m³/jaar',
						2: '1975',
						3: 'Eigendom van gemeente, verhuur situatie met exploitant.',
					}
				},
				2: {
					title: 'Tennisbaan',
					description: 'De tennisclub is erg bekend in de regio vanwege haar belangrijke netwerkfunctie en dé mooiste locatie van de gemeente Watertoren. De club is het hele jaar door actief door haar binnen- en buitenbanen.',
					statistic: {
						0: '60.000 kWh/jaar',
						1: '15.000 m³/jaar',
						2: '2010',
						3: 'Particulier - tennisclub',
					}
				},

			},
			advisor: {
				title: 'Kies een adviseur',
				'andrea': {
					name: 'Andrea',
					description: 'Andrea is een kei in het geven van inhoudelijk advies. Ze houdt het graag bij de feiten en heeft een focus op procesbegeleiding.',
					role: 'Energiecoach'
				},
				'ariean': {
					name: 'Ariean',
					description: 'Kijkt vooruit! 2030 en 2050 zijn niet ver weg. Ariean is bezig met het totaalplaatje en geeft graag zelf het goede voorbeeld.',
					role: 'Ambtenaar maatschappelijk vastgoed'
				},
				'celeste': {
					name: 'Celeste',
					description: 'Celeste is voornamelijk bezig met een vitale sportsector. Haar hart gaat sneller kloppen van alles wat met duurzame sport te maken heeft, in de breedste zin van het woord.',
					role: 'Ambtenaar sport'
				},
				'justin': {
					name: 'Justin',
					description: 'Justin houdt zich bezig met het behalen van de duurzaamheidsdoelstellingen van de gemeente Watertoren. Hij streeft naar het uitvoeren van plannen en samenwerking met verschillende partijen.',
					role: 'Ambtenaar duurzaamheid'
				},
				'lisa': {
					name: 'Lisa',
					description: 'Lisa heeft haar focus op een lage energierekening. Ze is dol op alles wat zichtbaar is voor de buitenwereld!',
					role: 'Sportbestuurder'
				}
			},
		},
		game: {
			tutorial: {
				title: 'Speluitleg',
				description: 'Je gaat drie maatregelen doorvoeren: energie besparen, opwekken en opslaan. Zoek de maatregelen rond om het gebouw en klik erop! Bij alle maatregelen zijn er meerdere manieren van verduurzamen mogelijk.',
				titleControls: '# Bediening',
				descriptionControls: `![wut](${tutorialImage} "uitleg")`
			},
			meter: {
				title: 'Duurzaamheids meter'
			},
			endgamebutton: 'rond spel af',
			categories: {
				'besparen': 'Energie besparen',
				'opwekken': 'Energie opwekken',
				'opslaan': 'Energie opslaan'
			},
			upgrades: {
				advisorTitle: 'Wat vindt'
			}
		},
		end: {
			'title': 'Gefeliciteerd!',
			'header1': 'Een overzicht van de energe besparingen:',
			'header2': 'Info over adviseurs:',
			'header3': 'Delen:',
			'header4': 'Activerend bericht:',
			'description1': 'Je hebt een mooi resultaat in CO₂-reductie behaald. Ga in gesprek met je collega’s, adviseurs en stakeholders en pak de opgave in jouw gemeente samen op. ',
			'description2': 'Deel deze game met andere en zet zo samen de eerste stap!',
			'description3': 'Wil je direct aan de slag in je eigen gemeente? Kijk dan op [duurzamesportsector.nl](https://www.duurzamesportsector.nl/). voor whitepapers, praktijkvoorbeelden, podcasts etc., die je helpen met de verduurzaming van je sportaccommodatie.',
			'description4': 'Lees bijvoorbeeld hoe je stap voor stap een succesvol verduurzamingsprogramma opzet voor je sportaccommodaties in [Hoe pas je de leidraad toe op je sportportefeuille?](https://www.kenniscentrumsportenbewegen.nl/kennisbank/publicaties/?hoe-pas-je-de-leidraad-toe-op-je-sportportefeuille&kb_id=26010&kb_q=) Of lees de whitepaper [Verduurzamen: Wie is aan zet?](https://www.kenniscentrumsportenbewegen.nl/producten/whitepaper-verduurzamen-wie-is-aan-zet/) over eigendomsverhoudingen. Wil je meer weten over welke subsidies je kan gebruiken, ga dan naar [Subsidiewijzer Verduurzaming Sportaccommodaties](https://www.kenniscentrumsportenbewegen.nl/producten/subsidiewijzer-verduurzaming-sportaccommodaties/) Wil je zien hoe andere gemeenten en verenigingen het doen, kijk dan eens naar de duurzaamheidsatlas. NB Deze link is vanaf medio juni beschikbaar. Wil je direct een offerte toegespitst op jouw sportaccommodatie of een energiecoach aanvragen, ga dan naar [Sport NL Groen](https://sportnlgroen.nl/sportnlgroen/).',
			'link1': 'Lees bijvoorbeeld hoe je stap voor stap een succesvol verduurzamingsprogramma opzet voor je sportaccommodaties in [Hoe pas je de leidraad toe op je sportportefeuille?](https://www.kenniscentrumsportenbewegen.nl/kennisbank/publicaties/?hoe-pas-je-de-leidraad-toe-op-je-sportportefeuille&kb_id=26010&kb_q=) Of lees de whitepaper [Verduurzamen: Wie is aan zet?](https://www.kenniscentrumsportenbewegen.nl/producten/whitepaper-verduurzamen-wie-is-aan-zet/) over eigendomsverhoudingen.',
			'link2': 'Wil je meer weten over welke subsidies je kan gebruiken, ga dan naar [Subsidiewijzer Verduurzaming Sportaccommodaties](https://www.kenniscentrumsportenbewegen.nl/producten/subsidiewijzer-verduurzaming-sportaccommodaties/).',
			'link3': 'Wil je zien hoe andere gemeenten en verenigingen het doen, kijk dan eens naar [de duurzaamheidsatlas](https://atlas.duurzamesportsector.nl/).',
			'link4': 'Wil je direct een offerte toegespitst op jouw sportaccommodatie aanvragen ga dan naar [Sport NL Groen](https://sportnlgroen.nl/sportnlgroen/).',
			'replay-button': 'Speel opnieuw',
			'more_button': 'meer weten'
		}
	},
	upgrades: nl_upgrades
};