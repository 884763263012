/* eslint-disable */
import { extend, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { useGlobalContext } from '../contexts/global/GlobalContext';
import { useThreeContext } from '../contexts/three/ThreeContext';
import { cameraPositions, scenesData } from '../data/data';
import { CameraControls } from '../utilities/YomatsuCameraControls';

export const ThreeControls = () => {

	const { setControls, gameState, selectedScene, activeCategory } = useGlobalContext();
	const { controlsEnabled, activeUpgrades, newestUpgrade, setControlsEnabled } = useThreeContext()

	const [targetPos, setTargetPos] = useState<Vector3 | undefined>();
	const cameraControls = useRef<CameraControls>(null);
	
	useEffect(() => {
		if(gameState === 4 && cameraControls.current){
			cameraControls.current.setLookAt(5,2,0,0,0,0, true);
		}
		else if(cameraControls.current){
			cameraControls.current.setLookAt(5,30,0,0,30,0, true);
		}
	},[gameState])

	// sets start position of the camera
	useEffect(() => {
		if(cameraControls.current && gameState !== 4){
			cameraControls.current.setLookAt(0,30,0,0,30,0);
		}
	},[])

	useEffect(() => {
		if(!cameraControls.current)return;
		setControls && setControls(cameraControls.current)
	},[cameraControls.current])

	//enable/disable control
	useEffect(() =>{
		if(!cameraControls.current || controlsEnabled === undefined )return;
		cameraControls.current.enabled = controlsEnabled
	},[controlsEnabled])

	useEffect(() => {
		if(newestUpgrade === undefined || !cameraControls.current) return;
		
		let all: {[key: string]: {cameraPos: number}} = {};
		Object.entries(scenesData[selectedScene].categories).map((cat) => all = {...all, ...cat[1].upgrades})
		const position = cameraPositions[all[newestUpgrade].cameraPos - 1]	
		
		// check if camera is already on that spot
		if(cameraControls.current.polarAngle === position[1] && cameraControls.current.azimuthAngle === position[0]){
			setControlsEnabled && setControlsEnabled(true)
		// move and enable controls when done
		} else {
			cameraControls.current.rotateTo(position[0], position[1], true)
			cameraControls.current.addEventListener('rest', () => setControlsEnabled && setControlsEnabled(true));
		}

	},[activeUpgrades])

	return (
		<CameraControls ref={cameraControls}/>
	);
};