import i18next from 'i18next';
import Plausible from 'plausible-tracker';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import App from './App';
import { translations } from './localization/translations';

// analytics

const { trackPageview } = Plausible({
	domain: 'kcsteasergame',
	hashMode: true,
	// trackLocalhost: true
});
  
trackPageview();  

// translations

i18next.use(initReactI18next).init({
	resources: translations,
	lng: 'nl', // if you're using a language detector, do not define the lng option
	fallbackLng: 'nl',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
