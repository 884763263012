import ContextProvider from './contexts/ContextProvider';
import CustomThemeProvider from './style/CustomThemeProvider';
import Backdrop from './interface/Backdrop';
import { DeveloperConsole } from './interface/console/DeveloperConsole';
import { Interface } from './interface/Interface';
import { Three } from './three/Three';

function App() {
	return (
		<>
			<CustomThemeProvider>
				<ContextProvider>
					<Backdrop/>
					<DeveloperConsole/>
					<Three/>
					<Interface/>
				</ContextProvider>
			</CustomThemeProvider>
		</>
	);
}

export default App;
