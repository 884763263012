import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../contexts/global/GlobalContext';

const amountOfStates = 6;

export const DCGeneral = () => {

	const {gameState, setGameState, overwriteGameState, setOverwriteGameState} = useGlobalContext();
	const [overwriteValue, setOverwriteValue] = useState<number | undefined>(undefined);

	useEffect(() => {
		localStorage.setItem('overwriteGameState', JSON.stringify(overwriteGameState));
	},[overwriteGameState]);

	return(
		<>
			<label>
				gameState
				<select onChange={(e) => setGameState && setGameState(parseInt(e.currentTarget.value))} value={gameState}>
					{ [...Array(amountOfStates)].map((option, index) => <option key={`dc-general-gamestate-option-${index}`}>{index}</option>)}
				</select>
			</label>
			
			<label>
				overwrite initial gameState
				<select onChange={(e) => setOverwriteGameState && setOverwriteGameState(parseInt(e.currentTarget.value))} value={overwriteGameState}>
					<option>no overwrite</option>
					{ [...Array(amountOfStates)].map((option, index) => <option key={`dc-general-gamestate-overwrite-option-${index}`}>{index}</option>)}
				</select><br/>
			</label>

		</>
	);
};