import { createGlobalStyle } from 'styled-components';
import Theme from './theme';

//eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	${fonts};

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;
		overflow: hidden;
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
		overscroll-behavior: contain;
		overflow: hidden;
		background-color:${Theme.colors.neutralLightest};
		color:${Theme.colors.neutralDarkest};
		font-family: 'Lato', sans-serif;
	}

	#root {
		height: 100%;
		overflow: hidden;
	}

	* {
		box-sizing: border-box;
		user-select: none;
	}

	h1, h2, h3{
		
		margin-top: 0;
		color: ${Theme.colors.primary};
		// Extra small screens
		@media (max-width: ${Theme.responsive.media.sm}){
			font-size: 1.5em;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			font-size: 2em;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			font-size: 2.25em;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			font-size: 2.5em;
		}

	}

	h2 {
		@media (max-width: ${Theme.responsive.media.sm}){
			font-size: 1.25em;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			font-size: 1.5em;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			font-size: 1.75em;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			font-size: 2em;
		}
	}

	p {
		margin:0;

		// Extra small screens
		@media (max-width: ${Theme.responsive.media.sm}){
			font-size: 1em;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${Theme.responsive.media.sm}){
			font-size: 1.25em;
		}

		// Large screens
		@media (min-width: ${Theme.responsive.media.lg}){
			font-size: 1.5em;
		}

		// Extra large screens
		@media (min-width: ${Theme.responsive.media.xl}){
			font-size: 1.5em;
		}

	}

	/* custom scrollbar */

	/* width */
	::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${Theme.colors.neutralLighter};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background:  ${Theme.colors.neutral}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

	img  {
		max-width: 100%;
	}

`;

export default GlobalStyle;
